import React from 'react';
import styled from 'styled-components';

const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  background-color: black;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .content {
    position: relative;
    z-index: 2;
  }

  h1 {
    font-size: 4rem;
    font-family: 'Futura Display', sans-serif;
    color: #e50914; /* Netflix Red */
  }

  p {
    font-size: 1.5rem;
    font-family: 'Open Sans', sans-serif;
    color: #ffffff;
    margin-top: 1rem;
  }
`;

const AboutHeroSection = () => {
  return (
    <HeroSection>
      <video autoPlay loop muted>
        <source src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/Jacson.mov?alt=media" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <h1>10th Inning</h1>
        <p>Empowering Athletes to Reach Their Full Potential</p>
      </div>
    </HeroSection>
  );
};

export default AboutHeroSection;
