// src/components/PlayerShowcase.js
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import CustomArrow from './CustomArrow';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/PlayerShowcase.css';

const Section = styled.section.attrs({
  className: 'player-showcase-section',
})``;

const Title = styled.h2``;

const ImageContainer = styled.div`
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
`;

const ImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background-color: #1e1e1e;
  border-radius: 15px;
  overflow: hidden;
`;

const Image = styled.img.attrs({
  className: 'player-image',
})`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Prevents stretching */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
`;

const ShowcaseOverlay = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 1.2em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  padding: 5px 10px;
  border-radius: 5px;
`;

const PlayerShowcase = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Faster transition between images
    pauseOnHover: true,
    centerMode: true,
    centerPadding: '60px',
    arrows: false, // Disable default arrows
    nextArrow: <CustomArrow icon={<FaChevronRight />} />,
    prevArrow: <CustomArrow icon={<FaChevronLeft />} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false, // Ensure no default arrows on smaller screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
          arrows: false, // Ensure no default arrows on smaller screens
        },
      },
    ],
  };

  return (
    <Section>
      <Title>Our Elite Athletes</Title>
      <Slider {...settings}>
        <ImageContainer>
          <ImageBox>
            <Image src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/Slide4.jpeg?alt=media" alt="Integrity" />
            <ShowcaseOverlay>Integrity</ShowcaseOverlay>
          </ImageBox>
        </ImageContainer>
        <ImageContainer>
          <ImageBox>
            <Image src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/Slide5.jpeg?alt=media" alt="Human-Centered Care" />
            <ShowcaseOverlay>Human-Centered Care</ShowcaseOverlay>
          </ImageBox>
        </ImageContainer>
        <ImageContainer>
          <ImageBox>
            <Image src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/slideS2.jpeg?alt=media" alt="Empathy" />
            <ShowcaseOverlay>Empathy</ShowcaseOverlay>
          </ImageBox>
        </ImageContainer>
        <ImageContainer>
          <ImageBox>
            <Image src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/SlideS3.jpeg?alt=media" alt="Clarity" />
            <ShowcaseOverlay>Clarity</ShowcaseOverlay>
          </ImageBox>
        </ImageContainer>
        <ImageContainer>
          <ImageBox>
            <Image src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/Slide4.jpeg?alt=media" alt="Passion" />
            <ShowcaseOverlay>Passion</ShowcaseOverlay>
          </ImageBox>
        </ImageContainer>
        <ImageContainer>
          <ImageBox>
            <Image src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/Slide5.jpeg?alt=media" alt="Inclusion" />
            <ShowcaseOverlay>Inclusion</ShowcaseOverlay>
          </ImageBox>
        </ImageContainer>
        <ImageContainer>
          <ImageBox>
            <Image src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/slideS2.jpeg?alt=media" alt="Discipline" />
            <ShowcaseOverlay>Discipline</ShowcaseOverlay>
          </ImageBox>
        </ImageContainer>
      </Slider>
    </Section>
  );
};

export default PlayerShowcase;
