import React from 'react';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import './styles/GrowthMetrics.css';

const Section = styled.section.attrs({
    className: 'growth-metrics-section',
})``;

const Title = styled.h2``;

const Subtitle = styled.p``;

const ExplanationGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
    background-color: #1f1f1f;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const Explanation = styled.div`
    color: #b3b3b3;
`;

const Image = styled.img`
    width: 100%;
    border-radius: 8px;
`;

const ChartContainer = styled.div.attrs({
    className: 'chart-container',
})``;

const data = [
    { year: '2021-2022', Teams: 468, Players: 6552, Parents: 19656, Estimated_Attendees: 26208 },
    { year: '2022-2023', Teams: 621, Players: 8694, Parents: 26082, Estimated_Attendees: 32536 },
    { year: '2023-2024', Teams: 748, Players: 10472, Parents: 31416, Estimated_Attendees: 41888 },
];

const attendeeBreakdown = [
    { name: 'Teams', value: 1837 },
    { name: 'Parents', value: 77154 },
    { name: 'Athletes', value: 25718 },
    { name: 'Coaches', value: 7308 },
    { name: 'Estimated Number of Attendees', value: 100632 },
    { name: 'Recruiters', value: 200 },
];

const COLORS = ['#E50914', '#1E90FF', '#32CD32', '#FFD700', '#800080', '#FFFFFF', '#808080'];

const GrowthMetrics = () => {
    return (
        <>
            <Section>
                <Title>Transformative Growth</Title>
                <Subtitle>Charting a Path of <span className="highlight">Unprecedented Impact</span> and <span className="highlight">Influence</span></Subtitle>
                <ExplanationGrid>
                    <Explanation>
                        <p>
                            In today's dynamic landscape, growth isn't just measured in numbers but in the depth of connections and the breadth of impact. Our growth narrative is a powerful testament to the <span className="highlight">relentless pursuit of excellence</span> and <span className="highlight">community engagement</span>. Each chart here is more than a metric; it's a story of our <span className="highlight">expanding reach</span>, <span className="highlight">vibrant community</span>, and the <span className="highlight">compelling opportunities</span> that lie ahead for our partners. By aligning with us, sponsors tap into a <span className="highlight">thriving ecosystem</span> that promises <span className="highlight">heightened visibility</span>, <span className="highlight">robust engagement</span>, and a <span className="highlight">shared journey towards transformative impact</span>.
                        </p>
                    </Explanation>
                    <Image src="/assets/SlideS3.jpeg" alt="Growth Metrics" />
                </ExplanationGrid>
                <ChartContainer className="pie-chart-container">
                    <h3>Audience Composition</h3>
                    <p className="chart-description">Discover the diverse and dynamic audience that forms the core of our events, providing a fertile ground for <span className="highlight">brand interaction</span> and <span className="highlight">influence</span>.</p>
                    <ResponsiveContainer width="100%" height={500}>
                        <PieChart>
                            <Pie
                                data={attendeeBreakdown}
                                cx="50%"
                                cy="50%"
                                outerRadius={150}
                                fill="#8884d8"
                                dataKey="value"
                                label
                            >
                                {attendeeBreakdown.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </ChartContainer>
                <ChartContainer>
                    <h3>Yearly Growth of Teams</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={data}>
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Teams" fill="#E50914" />
                        </BarChart>
                    </ResponsiveContainer>
                </ChartContainer>
                <ChartContainer>
                    <h3>Yearly Growth of Players</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={data}>
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Players" stroke="#1E3A5F" />
                        </LineChart>
                    </ResponsiveContainer>
                </ChartContainer>
                <ChartContainer>
                    <h3>Yearly Growth of Parents</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={data}>
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Parents" stroke="#ff7300" />
                        </LineChart>
                    </ResponsiveContainer>
                </ChartContainer>
                <ChartContainer>
                    <h3>Yearly Growth of Estimated Attendees</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={data}>
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Estimated_Attendees" stroke="#82ca9d" />
                        </LineChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </Section>
        </>
    );
};

export default GrowthMetrics;
