import React from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Legend } from 'recharts';
import './styles/DataVisualization.css';

const ageGenderData = [
  { name: 'Athletes (12-18, Male)', value: 25718, color: '#1E90FF' },
  { name: 'Coaches (25-45, Male)', value: 7308, color: '#32CD32' },
  { name: 'Parents (30-55, Female)', value: 37034, color: '#FFD700' },
  { name: 'Parents (30-55, Male)', value: 40120, color: '#FFA500' },
  { name: 'Grandparents (60+, Female)', value: 19288, color: '#800080' },
  { name: 'Grandparents (60+, Male)', value: 19288, color: '#FF6347' },
];

const ageData = [
  { ageGroup: '12-18', group: 'Athletes', value: 25718 },
  { ageGroup: '25-45', group: 'Coaches', value: 7308 },
  { ageGroup: '30-55', group: 'Parents', value: 77154 },
  { ageGroup: '60+', group: 'Grandparents', value: 38577 },
];

const genderData = [
  { name: 'Female', value: 56322 },
  { name: 'Male', value: 92434 },
];

const COLORS = ['#E50914', '#1E90FF', '#32CD32', '#FFD700', '#FFA500', '#800080', '#FF6347'];

const DataVisualization = () => {
  return (
    <div className="sponsor-page-data-visualization">
      <h2>Audience Demographics</h2>
      <div className="sponsor-page-chart-container">
        <h3>Age Distribution</h3>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={ageData}>
            <XAxis dataKey="ageGroup" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#8884d8">
              {ageData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="sponsor-page-chart-container">
        <h3>Gender Distribution</h3>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              data={genderData}
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
              label
            >
              {genderData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="sponsor-page-chart-container">
        <h3>Overall Audience Composition</h3>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              data={ageGenderData}
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
              label
            >
              {ageGenderData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DataVisualization;
