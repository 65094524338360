// src/components/EventsCalendar.js
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import './styles/EventsCalendar.css';

const EventsCalendar = ({ events = [] }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEvents, setSelectedEvents] = useState([]);

  const parseEventDate = (dateString) => {
    const dateParts = dateString.split('-').map(part => part.trim());
    const startDate = new Date(dateParts[0]);
    const endDate = dateParts[1] ? new Date(dateParts[1]) : startDate;
    return { startDate, endDate };
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const eventsForDate = events.filter(event => {
      const { startDate, endDate } = parseEventDate(event.date);
      return date >= startDate && date <= endDate;
    });
    setSelectedEvents(eventsForDate);
  };

  return (
    <div className="events-calendar-page">
      <div className="description">
        <h1>Event Calendar</h1>
        <p>
          Welcome to the 10th Inning Event Calendar! This page provides an overview of our upcoming events, including showcases, tournaments, and special training sessions. Whether you're a player, coach, parent, or recruiter, you'll find opportunities to engage with top-tier athletic talent. Explore the calendar to stay updated on our latest events and make sure you don't miss out on any exciting opportunities!
        </p>
      </div>
      <div className="events-calendar">
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          tileContent={({ date }) =>
            events.some(event => {
              const { startDate, endDate } = parseEventDate(event.date);
              return date >= startDate && date <= endDate;
            }) && <div className="event-dot" />
          }
        />
        <div className="event-details">
          <h2>Events on {selectedDate.toDateString()}</h2>
          {selectedEvents.length > 0 ? (
            selectedEvents.map((event, index) => (
              <div key={index} className="event-item">
                <h3>{event.name}</h3>
                <p>{event.summary}</p>
              </div>
            ))
          ) : (
            <p>No events on this day.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventsCalendar;
