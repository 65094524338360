// src/components/data/athletes.js
const athletes = [
    { id: '1', name: "John Sotto", announcement: "Recruited by University of Colorado Spring", details: "More details about John Sotto." },
    { id: '2', name: "Christian Lewis", announcement: "Achieved a batting average of .350 this season", details: "More details about Christian Lewis." },
    { id: '3', name: "Murph Stehly", announcement: "Drafted 10th Round by Washington Nationals", details: "More details about Murph Stehly." },
    { id: '4', name: "Kyle Nevin", announcement: "Drafted 11th Round LA Dodgers", details: "More details about Kyle Nevin." },
    // Add more athletes and their announcements here
  ];
  
  export default athletes;
  