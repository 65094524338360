import React from 'react';
import styled from 'styled-components';
import AboutHeroSection from '../AboutHeroSection';
import './styles/About.css';

const Section = styled.section.attrs({
  className: 'about-section'
})``;

const GridContainer = styled.div.attrs({
  className: 'about-grid-container'
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
`;

const Title = styled.h1.attrs({
  className: 'about-title'
})``;

const Subtitle = styled.h2.attrs({
  className: 'about-subtitle'
})``;

const Paragraph = styled.p.attrs({
  className: 'about-paragraph'
})``;

const List = styled.ul.attrs({
  className: 'about-list'
})``;

const ListItem = styled.li.attrs({
  className: 'about-list-item'
})``;

const Highlight = styled.span.attrs({
  className: 'about-highlight'
})``;

const LeadershipSection = styled.section.attrs({
  className: 'about-leadership-section'
})``;

const LeaderTitle = styled.h2.attrs({
  className: 'about-leader-title'
})``;

const LeaderContainer = styled.div.attrs({
  className: 'about-leader-container'
})``;

const Leader = styled.div.attrs({
  className: 'about-leader'
})``;

const LeaderImage = styled.img.attrs({
  className: 'about-leader-image'
})``;

const LeaderName = styled.h3.attrs({
  className: 'about-leader-name'
})``;

const LeaderRole = styled.p.attrs({
  className: 'about-leader-role'
})``;

const About = () => {
  return (
    <>
      <AboutHeroSection />

      <Section>
        <GridContainer>
          <div>
            <Title>Mission Statement</Title>
            <Paragraph>
              Our mission is to <Highlight>empower athletes</Highlight> by providing customized evaluations and development plans, leveraging advanced technology to illuminate the path to excellence. We aim to revolutionize the sports landscape, offering athletes venues to compete, gain exposure, and enjoy their journey to becoming top-tier individuals and athletes.
            </Paragraph>
          </div>
          <div>
            <iframe 
              width="560" 
              height="315" 
              src="https://www.youtube-nocookie.com/embed/dRryCs7Zx1U?si=AD3Xrz4igGkxXweH" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowfullscreen
            ></iframe>
          </div>
        </GridContainer>
        
        <GridContainer>
          <div>
            <iframe 
              width="560" 
              height="315" 
              src="https://www.youtube-nocookie.com/embed/Z9e1xuA_LMI?si=5s1DXyOPNYAeyY3h&amp;start=7" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <Subtitle>Vision</Subtitle>
            <Paragraph>
              To be the <Highlight>gold standard</Highlight> in sports evaluation, development, and recruitment. We envision a future where every athlete can reach their highest potential through precision, innovation, and holistic development.
            </Paragraph>
          </div>
        </GridContainer>

        <Subtitle>Core Values</Subtitle>
        <List>
          <ListItem><Highlight>Integrity:</Highlight> Acting with honesty and consistency in all we do.</ListItem>
          <ListItem><Highlight>Human-Centered Care:</Highlight> Prioritizing the wellbeing and success of our customers and team.</ListItem>
          <ListItem><Highlight>Empathy:</Highlight> Understanding the needs of our community deeply and making a difference.</ListItem>
          <ListItem><Highlight>Clarity:</Highlight> Maintaining simplicity and transparency in our communication.</ListItem>
          <ListItem><Highlight>Passion:</Highlight> Guiding us through change and inspiring us to make a difference in the sports ecosystem.</ListItem>
          <ListItem><Highlight>Inclusion:</Highlight> Promoting acceptance and value for every member of our community.</ListItem>
          <ListItem><Highlight>Discipline:</Highlight> Believing in the transformative power of repeated excellence for sustainable progress.</ListItem>
        </List>
      </Section>

      <LeadershipSection>
        <LeaderTitle>Our Leadership</LeaderTitle>
        <LeaderContainer>
          <Leader>
            <LeaderImage src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/derric10.png?alt=media" alt="Derric Waller" />
            <LeaderName>Derric Waller</LeaderName>
            <LeaderRole>Chief Executive Officer</LeaderRole>
          </Leader>
          <Leader>
            <LeaderImage src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/Kwame10.png?alt=media" alt="Kwame Kyei" />
            <LeaderName>Kwame Kyei</LeaderName>
            <LeaderRole>Chief Technology Officer</LeaderRole>
          </Leader>
          <Leader>
            <LeaderImage src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/Gerric.png?alt=media" alt="Gerric Waller" />
            <LeaderName>Gerric Waller</LeaderName>
            <LeaderRole>Chief Development Officer</LeaderRole>
          </Leader>
          <Leader>
            <LeaderImage src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/max10.png?alt=media" alt="Maxime Ouellette" />
            <LeaderName>Maxime Ouellette</LeaderName>
            <LeaderRole>Chief Operating Officer</LeaderRole>
          </Leader>
        </LeaderContainer>
      </LeadershipSection>
    </>
  );
};

export default About;
