import React from 'react';
import './styles/SponsorInfo.css';
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';
import PremierYearLongSponsor from './PremierYearLongSponsor';
import DataVisualization from './DataVisualization';

const attendeeBreakdown = [
    { name: 'Teams', value: 1837 },
    { name: 'Parents', value: 77154 },
    { name: 'Athletes', value: 25718 },
    { name: 'Coaches', value: 7308 },
    { name: 'Estimated Number of Attendees', value: 100632 },
    { name: 'Recruiters', value: 200 },
];

const socialMediaData = [
    { name: 'Instagram: NCS San Diego', value: 1572 },
    { name: 'Instagram: 5Star_recruits', value: 2660 },
    { name: 'Instagram: Big Recruits', value: 716 },
    { name: 'Twitter: Five Star Mafia West SD', value: 745 },
    { name: 'Facebook', value: 875 },
    { name: 'LinkedIn', value: 3615 },
];

const COLORS = ['#E50914', '#1E90FF', '#32CD32', '#FFD700', '#800080', '#FFFFFF', '#808080'];

const SponsorInfo = () => {
  return (
    <section className="sponsor-page-info-section">
      <div className="sponsor-page-info-header">
        <h2>Become a Sponsor</h2>
        <p>Join us in making a difference. Your sponsorship helps support the next generation of athletes and brings your brand to a passionate and engaged audience.</p>
      </div>
      <div className="sponsor-page-info-target-audience sponsor-page-info-pie-chart-container">
        <h3>200K+ Engaged Attendees within 4 Years</h3>
        <p className="sponsor-page-info-description">Our events have a significant reach and impact, attracting a diverse audience of parents, grandparents, athletes, coaches, and recruiters. Here’s a breakdown of our attendees:</p>
        <ResponsiveContainer width="100%" height={500}>
          <PieChart>
            <Pie
              data={attendeeBreakdown}
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
              label
            >
              {attendeeBreakdown.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="sponsor-page-info-social-media-reach sponsor-page-info-pie-chart-container">
        <h3>Over 10K+ Followers Across Social Media</h3>
        <ResponsiveContainer width="100%" height={500}>
          <PieChart>
            <Pie
              data={socialMediaData}
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
              label
            >
              {socialMediaData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <DataVisualization />
      <div className="sponsor-page-info-packages">
        <h3>Sponsorship Packages</h3>
        <p className="sponsor-page-info-description-tournament">We host tournaments <span className="highlight">twice a month</span>, each attracting an average of <span className="highlight">40 teams</span>. Our largest tournaments feature <span className="highlight">over 100 teams</span>. These events bring together a wide array of attendees including coaches, parents, grandparents, siblings, aunts, uncles, and friends, providing sponsors with <span className="highlight">frequent and extensive exposure</span> to a <span className="highlight">highly engaged audience</span>.</p>
        <ul>
          <li>
            <h4>Grand Slam Sponsor Package - $25,000</h4>
            <p>Exclusivity: Main sponsor branding, VIP Access: Exclusive seating areas, Engagement Booth: Premier booth location, Keynote Opportunity: Speak at ceremonies, Media Coverage: Featured in media releases and event photography, Custom Content: Branded video content shared on social media, Recruiter Engagement: Co-host recruiter luncheon/seminar, Photo Backdrop Wall: Branding on athlete photo backdrop, Branded Tent: Sponsor’s logo on tent and product display.</p>
            <button className="sponsor-page-info-cta-button">Inquire Now</button>
          </li>
          <li>
            <h4>Home Run Sponsor Package - $15,000</h4>
            <p>Branding: Logo on tournament banners, Interactive Zone: Sponsor family-friendly games, Product Sampling: Limited giveaways/samples, Social Media Spotlights: Regular mentions/posts, Player Highlight Videos: Branding in highlight videos.</p>
            <button className="sponsor-page-info-cta-button">Inquire Now</button>
          </li>
          <li>
            <h4>Triple Sponsor Package - $10,000</h4>
            <p>Visibility: Logo on schedules and flyers, Hospitality: Access to branded hospitality tent, Announcements: Sponsor mentions during 5th inning, Recruitment Support: Branding on recruitment packets.</p>
            <button className="sponsor-page-info-cta-button">Inquire Now</button>
          </li>
          <li>
            <h4>Double Sponsor Package - $7,500</h4>
            <p>Exposure: Name on event website and banner, Community Engagement: Sponsor specific game/team, Event Program Feature: Inclusion in event program.</p>
            <button className="sponsor-page-info-cta-button">Inquire Now</button>
          </li>
          <li>
            <h4>Single Hit Sponsor Package - $5,000</h4>
            <p>Local Engagement: For East County businesses, Advertising: Name on local ads and booth, Community Support: Recognition in pre-event communications, Athlete Gear: Branded sports gear for participants.</p>
            <button className="sponsor-page-info-cta-button">Inquire Now</button>
          </li>
        </ul>
      </div>
      <PremierYearLongSponsor />
              {/*<div className="sponsor-page-info-testimonials">
        <h3>What Our Sponsors Say</h3>
        <p>“Sponsoring the Big Recruits Tournament has been an incredible experience for our brand. The exposure and engagement we received were unparalleled.” – John Doe, CEO of XYZ Corp.</p>
      </div>/*/}
      <div className="sponsor-page-info-benefits">
        <h3>Sponsorship Benefits</h3>
        <p>As a sponsor, you will receive prominent logo placement, direct engagement opportunities, support for youth sports, exclusive content, and networking opportunities with a diverse audience of parents, coaches, athletes, and industry professionals.</p>
      </div>
      <div className="sponsor-page-info-contact">
        <h3>Contact Us</h3>
        <form className="sponsor-page-info-contact-form">
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" placeholder="Your Message" required></textarea>
          <button type="submit" className="sponsor-page-info-contact-button">Send Message</button>
        </form>
      </div>
    </section>
  );
};

export default SponsorInfo;
