import React from 'react';
import styled from 'styled-components';
import './styles/PlayersDrafted.css';

const Section = styled.section.attrs({
    className: 'players-drafted-section',
})``;

const Title = styled.h2``;

const Subtitle = styled.p``;

const PlayerList = styled.ul.attrs({
    className: 'player-list',
})``;

const PlayerItem = styled.li.attrs({
    className: 'player-item',
})``;

const PlayerName = styled.h3.attrs({
    className: 'player-name',
})``;

const PlayerUniversity = styled.p.attrs({
    className: 'player-university',
})``;

const PlayerDrafted = styled.p.attrs({
    className: 'player-drafted',
})``;

const players = [
    { name: "John Sotto", university: "University of Colorado Spring" },
    { name: "Christian Lewis", university: "University of Colorado Spring" },
    { name: "Micah Anselmo", university: "Chico State" },
    { name: "Wayne Moeck", university: "Colorado School of Mines" },
    { name: "Max Power", university: "Holy Cross" },
    { name: "Murph Stehly", university: "University of Texas", drafted: "10th Round by Washington Nationals" },
    { name: "Kyle Nevin", university: "Baylor University", drafted: "11th Round LA Dodgers" },
    { name: "Zach Reitmeyer", university: "Biola University" },
    { name: "Dom Buso", university: "Montana State Billings" },
    { name: "Cayden Katnik", university: "Sonoma State" },
    { name: "Jacob Serafini", university: "Lewis and Clark" },
    { name: "Tanner Blough", university: "Vassar College" },
    { name: "Zander Corbin", university: "UCSD" },
    { name: "Evan Ianciello", university: "Cal State Bakersfield" },
    { name: "Matt Noce", university: "University of New Mexico" },
    { name: "Kingston Liniak", university: "USD", drafted: "4th Round Draft Pick Detroit Tigers" },
    { name: "Luch Depamphilis", university: "Concordia" },
    { name: "Zach Sherman", university: "University of Arizona" },
    { name: "Bryce Roe", university: "New Mexico State" },
    { name: "Luca Martinez", university: "Washington State" },
    { name: "Nic Naccarato", university: "Univ San Francisco" },
    { name: "Nathan Brasher", university: "Brown" },
    { name: "Quincy Scott", university: "UCSB" },
    { name: "Blake Conrad", university: "Kansas Wesleyan" },
    { name: "Alex IIda", university: "St Marys" },
    { name: "Brett Williams", university: "St Marys" },
    { name: "Devin King", university: "UCSD" },
    { name: "Julian Custer", university: "UCSD" },
    { name: "Will Stickney", university: "USC" },
    { name: "Kevin Takeuchi", university: "USC" },
    { name: "Jacob Campagna", university: "San Jose State" },
    { name: "Max Farrell", university: "San Diego State" },
    { name: "Jackson Waller", university: "Nevada" },
    { name: "Carson Walker", university: "Case Western" },
    { name: "Gavin Mangano", university: "Lewis and Clark" },
    { name: "Miller Durham", university: "Point Loma Nazarene" },
    { name: "Frankie Carney", university: "UC Irvine" },
    { name: "Jake Bold", university: "Princeton" },
    { name: "Johnny Luetzow", university: "Santa Clara" },
    { name: "James Theodore", university: "Wenster University" },
    { name: "Spencer Carswell", university: "San Diego State" },
    { name: "Login Berzins", university: "Stanford University Football" },
    { name: "Evan Hiremath", university: "University of Wyoming Football" },
    { name: "DJ Ralph", university: "San Diego State" },
    { name: "Anthony Mezzcacappa", university: "College of Saint Elizabeth" },
    { name: "Cameron Rowe", university: "Utah Valley" },
    { name: "Zachary Farrell", university: "Army" },
    { name: "DJ Cardinal", university: "San Diego State" },
    { name: "Binyam Gebphardt", university: "Ohlone" },
    { name: "Tucker Biehl", university: "Ohlone" },
    { name: "Grayson Grinsell", university: "University of Oregon" },
    { name: "Colby Rafail", university: "San Francisco State" },
    { name: "Xavier Farnum", university: "San Diego State" },
    { name: "Luke Hanson", university: "University of Redlands" },
    { name: "Brenden Siriani", university: "Army" },
    { name: "Sam Hasegawa", university: "UCSD" },
    { name: "Gavin Graff", university: "Adelphi" },
    { name: "Luke Brunette", university: "University of San Francisco" },
    { name: "Mike Campagna", university: "University of San Francisco" },
    { name: "Oliver Santos", university: "Duke University" },
    { name: "Noah Ruiz", university: "Biloa University" },
    { name: "Ty Borgono", university: "Long Beach State" },
    { name: "Porter Rovin", university: "LMU" },
    { name: "Troy McCain", university: "Cal Poly" },
    { name: "Aaron Rettew", university: "Cal Poly" },
    { name: "Carson Turnquist", university: "Oklahoma" },
    { name: "Tyler Bremner", university: "UCSB" },
    { name: "Kyle Becker", university: "Nevada" },
    { name: "Gavin Turley", university: "Oregon State" },
    { name: "Jet Crews", university: "University of Dubuque" },
    { name: "Dillon Pearson", university: "Kansas State" },
    { name: "Harrison Humberstone", university: "Concordia" },
    { name: "Tim Scarlett", university: "San Jose State" },
    { name: "Grant Knox", university: "Linfield" },
    { name: "Jacob Grossman", university: "Emory" },
    { name: "Justin Grossman", university: "University of Rochester" },
    { name: "Diego Magdallon", university: "University of LaVerne" },
    { name: "Ian Fisher", university: "UCLA" },
    { name: "Jett Kenady", university: "Cal" },
    { name: "Matt Pisicane", university: "Whitworth" },
    { name: "Carson Daynes", university: "Southern Virginia University" },
    { name: "Jack Sanders", university: "Cal Poly Pomona" },
    { name: "David Zambelli", university: "Cal Lutheran" },
    { name: "Jaxon Scheibe", university: "Chapman University" },
    { name: "Kyle Rogers", university: "Biola University" },
    { name: "Jonathan Freedman", university: "MIT" },
    { name: "Jack Circuit", university: "BYU" },
    { name: "Will Stickney", university: "USC" },
    { name: "Kevin Takeuchi", university: "USC" },
    { name: "Max Bayles", university: "Santa Clara" },
    { name: "Jack West", university: "Hope International" },
    { name: "Dillon Foster", university: "Lewis and Clark" },
    { name: "Kayden Winfrey", university: "Lamar CC" },
    { name: "Rowan Haynes", university: "Seattle" },
    { name: "Brooks Bachman", university: "Colorado Mesa" },
    { name: "Brooks Lang", university: "Towson" },
    { name: "Tyler Foster", university: "Irvine" },
    { name: "Justin Heffler", university: "St. Marys" },
    { name: "Ryan Sanchez", university: "Freed Harman" },
    { name: "Liam Dufour", university: "College of Wooster" },
    { name: "Andrew Ramirez", university: "Rogers State" }
];

const draftedPlayers = players.filter(player => player.drafted);
const recruitedPlayers = players.filter(player => !player.drafted);

const PlayersDrafted = () => {
    return (
        <>
            <Section className="drafted-section">
                <Title>Our Players And Where They Were Drafted</Title>
                <Subtitle>Updated 6/16/2024</Subtitle>
                <PlayerList>
                    {draftedPlayers.map((player, index) => (
                        <PlayerItem key={index}>
                            <PlayerName>{player.name}</PlayerName>
                            <PlayerUniversity>{player.university}</PlayerUniversity>
                            <PlayerDrafted>Drafted: {player.drafted}</PlayerDrafted>
                        </PlayerItem>
                    ))}
                </PlayerList>
            </Section>
            
            <Section>
                <Title>Our Players And Where They Were Recruited</Title>
                <PlayerList>
                    {recruitedPlayers.map((player, index) => (
                        <PlayerItem key={index}>
                            <PlayerName>{player.name}</PlayerName>
                            <PlayerUniversity>{player.university}</PlayerUniversity>
                        </PlayerItem>
                    ))}
                </PlayerList>
            </Section>
        </>
    );
};

export default PlayersDrafted;
