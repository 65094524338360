// src/components/Hero.js
import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './styles/Hero.css';
import './styles/ImpactStats.css';

const Hero = () => {
    const impactRef = useRef(null);
    const controls = useAnimation();
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    useEffect(() => {
        if (!impactRef.current || !hasMounted) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        controls.start({ opacity: 1, y: 0 });
                    } else {
                        controls.start({ opacity: 0, y: 20 });
                    }
                });
            },
            { threshold: 0.5 }
        );

        observer.observe(impactRef.current);

        return () => {
            if (impactRef.current) {
                observer.unobserve(impactRef.current);
            }
        };
    }, [controls, hasMounted]);

    const impactStats = [
        { number: '200,000+', label: 'ATTENDEES OVER 4 YEARS' },
        { number: '25,000+', label: 'ATHLETES' },
        { number: '77,000+', label: 'PARENTS' },
        { number: '7,000+', label: 'COACHES' },
    ];

    return (
        <>
            <section className="hero-section">
                <video className="hero-video" autoPlay loop muted>
                    <source src="https://firebasestorage.googleapis.com/v0/b/inning-e7617.appspot.com/o/heroVid.mp4?alt=media" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="overlay" />
                <div className="content">
                    <h1 className="hero-title">Welcome to 10th Inning</h1>
                    <h2 className="hero-subtitle">Empowering Athletes to Reach Their Full Potential</h2>
                    <button className="hero-button">Get Started</button>
                </div>
            </section>
            <section className="impact-stats" ref={impactRef}>
                {impactStats.map((stat, index) => (
                    <motion.div
                        key={index}
                        className="impact-stat"
                        initial={{ opacity: 0, y: 20 }}
                        animate={controls}
                        transition={{ delay: index * 0.2, duration: 0.6 }}
                        whileHover={{ scale: 1.05 }}
                    >
                        <span className="impact-number">{stat.number}</span>
                        <span className="impact-label">{stat.label}</span>
                    </motion.div>
                ))}
            </section>
        </>
    );
};

export default Hero;
