// src/components/Event.js
import React from 'react';
import './styles/Event.css';

const Event = ({ name, date, summary, details, feeStructure, venues }) => {
  return (
    <div className="event">
      <h2 className="event-name">{name}</h2>
      <p className="event-date">{date}</p>
      <p className="event-summary">{summary}</p>
      <div className="event-info">
        <h3 className="event-section-title">Confirmed Colleges from 2024</h3>
        <p className="event-text">Biola University, Cal State San Bernardino, Bethesda University, Cal State San Marcos, Point Loma Nazarene, Hope International</p>

        <h3 className="event-section-title">Player Highlights</h3>
        <p className="event-text">On Instagram: <span className="social-handle">@ncis_prospects</span>, <span className="social-handle">@big_recruits</span>, <span className="social-handle">@5star_recruits</span></p>

        <h3 className="event-section-title">Game Video Submission</h3>
        <p className="event-text">Send game video to <span className="contact-info">858-472-1075</span> for posting</p>

        <h3 className="event-section-title">2023 Champs and Finalists</h3>
        <p className="event-text">Details of champions and finalists for various categories</p>

        <h3 className="event-section-title">Top Performers</h3>
        <p className="event-text">Top performers will be highlighted on Twitter and Instagram</p>

        <h3 className="event-section-title">Event Equipment</h3>
        <p className="event-text">Baseballs supplied for the event</p>
      </div>
      <div className="event-fees">
        <h3 className="event-section-title">Fee Structure</h3>
        <ul>
          {feeStructure.map((fee, index) => (
            <li key={index}>{fee}</li>
          ))}
        </ul>
      </div>
      <div className="event-venues">
        <h3 className="event-section-title">Venues</h3>
        <ul>
          {venues.map((venue, index) => (
            <li key={index}>{venue}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Event;
