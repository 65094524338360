// src/pages/UpcomingEvents.js
import React from 'react';
import Event from '../components/Event';
import './styles/UpcomingEvents.css';

const UpcomingEvents = () => {
  const events = [
    {
      name: "4th ANNUAL BIG RECRUITS SHOWCASE",
      date: "August 24-25, 2024",
      summary: "Top Recruits Tournament Showcase with a 3-game guarantee. Top performers will be added to the tournament leaderboard.",
      details: [
        "2024-2025 Age Groups",
        "Confirmed Colleges from 2024: Biola University, Cal State San Bernardino, Bethesda University, Cal State San Marcos, Point Loma Nazarene, Hope International",
        "Player Highlights on Instagram @ncis_prospects, @big_recruits, @5star_recruits",
        "Send game video to 858-472-1075 for posting",
        "2023 CHAMPS AND FINALISTS: 14U - REBELS BBC CHAMPS - 5 STAR SD GOLD FINALISTS; 16U - BRG PIRATES CHAMPS - DESERT DAWGS BBC FINALISTS; 18U - ZT PROSPECTS CHAMPS - 5 STAR 18U GOLD FINALISTS",
        "Top performers will be highlighted on Twitter and Instagram - Recruiters at the D2 level through NAIA invited",
        "Expecting to confirm a few more colleges this week leading up to the event.",
        "14U Division",
        "15U and 16U may be combined",
        "17U/18U Combines",
        "Wood bat event - 14U BBCOR or Wood",
        "Baseballs supplied for the event - Rings and Banner to the Champion"
      ],
      feeStructure: [
        "$925 for 14U",
        "$1200 for 15U/16U",
        "$1350 for 17U/18U"
      ],
      venues: [
        "Sportsplex USA Santee",
        "Sportsplex USA Poway"
      ]
    },
    // Additional events can be added here
  ];

  return (
    <div className="upcoming-events-page">
      <h1>Upcoming Events</h1>
      {events.length > 0 ? (
        <div className="events-list">
          {events.map((event, index) => (
            <Event key={index} {...event} />
          ))}
        </div>
      ) : (
        <p className="no-events-message">No upcoming events at the moment. Please check back later!</p>
      )}
    </div>
  );
};

export default UpcomingEvents;
