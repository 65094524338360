import React from 'react';
import './styles/PremierYearLongSponsor.css';

const PremierYearLongSponsor = () => {
  return (
    <div className="premier-sponsor-package">
      <h2 className="premier-sponsor-title">Premier Year-Long Sponsor Package - $100,000</h2>
      <p className="premier-sponsor-description">
        Unlock unparalleled visibility and engagement with our exclusive Premier Year-Long Sponsor Package. For <span className="highlight">$100,000</span> per year, your brand will take center stage at every event we host, including our <span className="highlight">bi-monthly tournaments</span>, <span className="highlight">daily clinics</span>, and <span className="highlight">travel ball teams</span>. This comprehensive package guarantees maximum exposure and engagement with a highly targeted audience of sports enthusiasts, athletes, and their families.
      </p>
      <div className="premier-sponsor-table">
        <table>
          <thead>
            <tr>
              <th>Feature</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Branding</td>
              <td>All-inclusive branding across all events</td>
            </tr>
            <tr>
              <td>VIP Access</td>
              <td>VIP access to all tournaments</td>
            </tr>
            <tr>
              <td>Exclusive Booth</td>
              <td>Exclusive booth at all events</td>
            </tr>
            <tr>
              <td>Keynote Opportunities</td>
              <td>Keynote speaking opportunities at major events</td>
            </tr>
            <tr>
              <td>Media Coverage</td>
              <td>Extensive media coverage and feature in all event-related media</td>
            </tr>
            <tr>
              <td>Custom Content</td>
              <td>Custom branded content shared on our platforms</td>
            </tr>
            <tr>
              <td>Engagement</td>
              <td>Dedicated recruiter and industry engagement opportunities</td>
            </tr>
            <tr>
              <td>Photo Backdrops</td>
              <td>Branding on athlete photo backdrops and event tents</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="premier-sponsor-stats">
        <h3>Impact Metrics:</h3>
        <p>Your sponsorship will reach:</p>
        <ul>
          <li>Over 200,000 attendees annually</li>
          <li>10,000+ social media followers</li>
          <li>Extensive local and national media coverage</li>
        </ul>
      </div>
      <div className="premier-sponsor-testimonials">
        <h3>What Our Sponsors Say:</h3>
        <blockquote>
          “Sponsoring the Big Recruits Tournament has been an incredible experience for our brand. The exposure and engagement we received were unparalleled”.
        </blockquote>
      </div>
      <button className="premier-sponsor-cta-button">Inquire Now</button>
    </div>
  );
};

export default PremierYearLongSponsor;
