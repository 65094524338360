// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './styles/Header.css';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header-container">
            <div className="logo-container">
                <Link to="/" onClick={() => setMenuOpen(false)}>
                    <img className="logo" src="/assets/10logo.png" alt="10th Inning Logo" />
                </Link>
            </div>
            <nav className={`nav ${menuOpen ? 'open' : ''}`}>
                <ul className={`nav-list ${menuOpen ? 'open' : ''}`}>
                    <li className="nav-item">
                        <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/growth-metrics" onClick={() => setMenuOpen(false)}>Growth Metrics</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/sponsor-info" onClick={() => setMenuOpen(false)}>Sponsorships</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/upcoming-events" onClick={() => setMenuOpen(false)}>Upcoming Events</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/events-calendar" onClick={() => setMenuOpen(false)}>Events Calendar</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contact-us" onClick={() => setMenuOpen(false)}>Contact</Link>
                    </li>
                    <div className="cta-container">
                        <Link className="cta-button" to="/get-started" onClick={() => setMenuOpen(false)}>Get Started</Link>
                    </div>
                </ul>
            </nav>
            <div className="hamburger-icon" onClick={toggleMenu}>
                {menuOpen ? <FaTimes /> : <FaBars />}
            </div>
        </header>
    );
};

export default Header;
