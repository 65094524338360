// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import ImpactSection from './components/ImpactSection';
import GrowthMetrics from './components/GrowthMetrics';
import About from './components/About';
import PlayerShowcase from './components/PlayerShowcase';
import PlayersDrafted from './components/PlayersDrafted';
import AthleteDetail from './components/AthleteDetail'; // Detailed athlete page
import SponsorInfo from './components/SponsorInfo'; // Sponsor Information page
import UpcomingEvents from './pages/UpcomingEvents'; // Updated path for Upcoming Events page
import EventsCalendar from './components/EventsCalendar'; // Events Calendar component

import GlobalStyles from './GlobalStyles';

const App = () => {
    return (
        <Router>
            <GlobalStyles />
            <Header />
            <Routes>
                <Route path="/" element={
                    <>
                        <Hero />
                        <ImpactSection />
                        <PlayerShowcase />
                        <PlayersDrafted />
                    </>
                } />
                <Route path="/growth-metrics" element={<GrowthMetrics />} />
                <Route path="/about" element={<About />} />
                <Route path="/sponsor-info" element={<SponsorInfo />} /> {/* Sponsor Information page */}
                <Route path="/upcoming-events" element={<UpcomingEvents />} /> {/* Upcoming Events page */}
                <Route path="/events-calendar" element={<EventsCalendar />} /> {/* Events Calendar page */}
                <Route path="/athlete/:id" element={<AthleteDetail />} /> {/* Athlete detail page */}
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
