import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import athletes from './data/athletes'; // Assume we have a data file

const DetailContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 80px auto 0; /* Added margin-top to prevent content from being cut off by the nav */
  background: #141414;
  color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
`;

const AthleteImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const AthleteName = styled.h1`
  color: #e50914; /* Netflix Red */
  font-size: 2.5em;
  margin-bottom: 10px;
`;

const AthleteAnnouncement = styled.p`
  font-size: 1.2em;
  margin: 10px 0;
`;

const AthleteDetails = styled.p`
  font-size: 1em;
  color: #888;
  line-height: 1.6;
`;

const AthleteDetail = () => {
  const { id } = useParams();
  const athlete = athletes.find(athlete => athlete.id === id);

  if (!athlete) {
    return <DetailContainer>Athlete not found</DetailContainer>;
  }

  return (
    <DetailContainer>
      <AthleteImage src={`https://via.placeholder.com/800x400?text=${athlete.name}`} alt={athlete.name} />
      <AthleteName>{athlete.name}</AthleteName>
      <AthleteAnnouncement>{athlete.announcement}</AthleteAnnouncement>
      <AthleteDetails>{athlete.details}</AthleteDetails>
    </DetailContainer>
  );
};

export default AthleteDetail;
