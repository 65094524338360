// src/components/CustomArrow.js
import React from 'react';
import styled from 'styled-components';

const ArrowButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 50%;
  color: #e50914; /* Netflix Red */
  font-size: 2em;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    color: #ffffff;
    background-color: rgba(229, 9, 20, 0.8); /* Darker Netflix Red */
  }
`;

const CustomArrow = ({ className, style, onClick, icon }) => {
  return (
    <ArrowButton
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      {icon}
    </ArrowButton>
  );
};

export default CustomArrow;
