// src/components/Footer.js
import React from 'react';
import { FaLinkedin, FaYoutube, FaEnvelope } from 'react-icons/fa';
import './styles/Footer.css'; // Importing the external CSS

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-grid">
                <div className="footer-section">
                    <h3>Connect With Us</h3>
                    <div className="social-links">
                        <a className="social-icon" href="https://www.linkedin.com/company/10th-inning-ventures/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin />
                        </a>
                        <a className="social-icon" href="https://www.youtube.com/channel/UCt5GVfkQUaI8vhqdLaU6Q6w" target="_blank" rel="noopener noreferrer">
                            <FaYoutube />
                        </a>
                        <a className="social-icon" href="mailto:info@10inning.com">
                            <FaEnvelope />
                        </a>
                    </div>
                </div>
                <div className="footer-section">
                    <h3>Contact Information</h3>
                    <p>Email: <a className="email-link" href="mailto:info@10inning.com">info@10inning.com</a></p>
                    <p>&copy; 2024 10th Inning. All Rights Reserved.</p>
                </div>
                <div className="footer-section">
                    <h3>Quick Links</h3>
                    <ul className="quick-links">
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/upcoming-events">Upcoming Events</a></li>
                        <li><a href="/sponsor-info">Sponsorships</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
