// src/components/ImpactSection.js
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './styles/ImpactSection.css';

const Section = styled.section.attrs({
    className: 'impact-section',
})``;

const Title = styled.h2``;

const Subtitle = styled.p``;

const Button = styled.button``;

const ImpactSection = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/growth-metrics');
    };

    const data = [
        { year: '2021-2022', Teams: 468, Players: 6552, Parents: 19656, Estimated_Attendees: 26208 },
        { year: '2022-2023', Teams: 621, Players: 8694, Parents: 26082, Estimated_Attendees: 32536 },
        { year: '2023-2024', Teams: 748, Players: 10472, Parents: 31416, Estimated_Attendees: 41888 },
    ];

    return (
        <Section>
            <Title>Our Impact</Title>
            <Subtitle>Showcasing our growth and reach year over year</Subtitle>
            <div className="chart-container">
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={data}>
                        <XAxis dataKey="year" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Teams" fill="#E50914" />
                        <Bar dataKey="Players" fill="#1E3A5F" />
                        <Bar dataKey="Parents" fill="#ff7300" />
                        <Bar dataKey="Estimated_Attendees" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <Button className="impact-button" onClick={handleButtonClick}>View Detailed Growth Metrics</Button>
        </Section>
    );
};

export default ImpactSection;
